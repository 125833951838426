import React from "react";
import { styled } from "../../../stitches.config"

import BackgroundDesktopGray from "../../assets/icons/footer-dots-desktop-gray.svg"

interface FooterBackgroundProps {
    isDarkMode?: boolean;
}

const FooterBackground: React.FC<FooterBackgroundProps> = ({ isDarkMode }) => {
    return (
        <Background>
            <div className={isDarkMode ? "gray-desktop" : "desktop"}>
                {!isDarkMode &&
                    <svg width="104" height="344" viewBox="0 0 104 344" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_9883_7445)">
                            <path d="M207.994 47.5819C208.218 67.987 202.281 87.9954 190.94 105.054C179.599 122.113 163.369 135.449 144.32 143.36C125.271 151.272 104.267 153.4 83.9874 149.473C63.7083 145.546 45.0735 135.743 30.4609 121.313C15.8483 106.884 5.92034 88.4828 1.94372 68.4579C-2.0329 48.433 0.122152 27.6922 8.13391 8.88189C16.1457 -9.92842 29.6509 -25.9554 46.9264 -37.154C64.2018 -48.3527 84.4643 -54.2154 105.128 -53.994C132.199 -53.3313 157.975 -42.4169 177.122 -23.5094C196.27 -4.60201 207.323 20.851 207.994 47.5819ZM207.994 306.189C208.218 326.595 202.281 346.603 190.94 363.662C179.599 380.721 163.369 394.057 144.32 401.968C125.271 409.879 104.267 412.007 83.9874 408.081C63.7083 404.154 45.0735 394.35 30.4609 379.921C15.8483 365.491 5.92034 347.09 1.94372 327.065C-2.0329 307.041 0.122152 286.3 8.13391 267.49C16.1457 248.679 29.6509 232.652 46.9264 221.454C64.2018 210.255 84.4643 204.392 105.128 204.614C132.199 205.276 157.975 216.191 177.122 235.098C196.27 254.006 207.323 279.459 207.994 306.189Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_9883_7445">
                                <rect width="104" height="344" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                }
            </div>
            <div className="mobile">
                <svg width="91" height="324" viewBox="0 0 91 324" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M181.995 6.01019C182.191 23.858 176.996 41.3588 167.073 56.2798C157.149 71.2008 142.948 82.8653 126.28 89.7852C109.612 96.705 91.2333 98.5663 73.489 95.1317C55.7447 91.697 39.4393 83.1222 26.6533 70.5011C13.8673 57.8801 5.18029 41.7851 1.70075 24.2699C-1.77879 6.7546 0.106883 -11.3868 7.11717 -27.8397C14.1275 -44.2926 25.9445 -58.3109 41.0606 -68.1061C56.1766 -77.9013 73.9062 -83.0292 91.9874 -82.8355C115.674 -82.2559 138.228 -72.7093 154.982 -56.1715C171.736 -39.6337 181.407 -17.3707 181.995 6.01019ZM181.995 232.207C182.191 250.055 176.996 267.556 167.073 282.477C157.149 297.398 142.948 309.063 126.28 315.982C109.612 322.902 91.2333 324.764 73.489 321.329C55.7447 317.894 39.4393 309.319 26.6533 296.698C13.8673 284.077 5.18029 267.982 1.70075 250.467C-1.77879 232.952 0.106883 214.81 7.11717 198.358C14.1275 181.905 25.9445 167.886 41.0606 158.091C56.1766 148.296 73.9062 143.168 91.9874 143.362C115.674 143.941 138.228 153.488 154.982 170.026C171.736 186.564 181.407 208.827 181.995 232.207Z" />
                </svg>
            </div>
        </Background>
    )
};

const Background = styled('div', {
    position: "absolute",
    right: 0,
    top: 0,
    "@bp4": {
        height: "100%",
        width: "104px",
    },
    "& .mobile": {
        "@bp4": {
            display: "none",
        },
    },
    "& .desktop": {
        display: "none",
        height: "100%",
        width: "104px",
        overflow: "hidden",
        "@bp4": {
            display: "block",
        },
    },
    "& .gray-desktop": {
        backgroundImage: `url("${BackgroundDesktopGray}")`,
        display: "none",
        height: "100%",
        width: "104px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "@bp4": {
            display: "block",
        },
    },
    "& svg": {
        path: {
            fill: "$primary",
        }
    }
})

export default FooterBackground;